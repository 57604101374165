<template>
  <v-container
    fluid
    tag="section"
    id="create-facture"
  >

    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Créer une nouvelle facture
            </div>

            <div class="subtitle-1 font-weight-light">
              Indiquez les informations
            </div>
          </template>

          <v-form
            ref="facture"
            method="post"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row class="mb-5">
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="N° facture *"
                    class="no-arrows"
                    v-model="facture.numero"
                    hint="Ex. : 123"
                    type="number"
                    disabled
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-autocomplete
                    outlined
                    label="Formation associée"
                    v-model="formationAssocieeId"
                    :items="formations"
                    item-text="intitule"
                    item-value="id"
                    clearable
                    @change="updateFactureWithInfosFormation(); formEdited = true"
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }}
                      <v-chip
                        class="ml-2"
                        x-small
                      >
                        {{ displayEtat[data.item.etat] }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    outlined
                    label="Client *"
                    v-model="facture.client"
                    :items="clients"
                    item-text="nomCommercial"
                    item-value="id"
                    :rules="[v => !!v || 'Indiquez le client']"
                    clearable
                    required
                    return-object
                    @change="updateReference();setFinanceurParDefaut();getLignesFactureFromBudget();getLignesFactureFromRecettes();formEdited = true"
                    :disabled="clientDisabled"
                  >
                    <template v-slot:no-data>
                      <!--
                      <div
                        v-if="!facture.formationAssociee"
                        class="px-4 py-3"
                      >
                        <i>Sélectionnez une formation pour faire apparaître les clients</i>
                      </div>
                      -->
                      <div
                      v-if="facture.formationAssociee"
                        class="px-4 py-3"
                      >
                        <i>Aucun client disponible</i>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-switch
                    :label="autofinancement ? 'Autofinancement' : 'Financement externe'"
                    v-model="autofinancement"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    v-if="!autofinancement"
                    outlined
                    label="Financeur *"
                    v-model="facture.destinataire"
                    :items="financeurs"
                    item-text="nomCommercial"
                    item-value="id"
                    :rules="[v => !!v || 'Indiquez le financeur (destinataire de la facture)']"
                    clearable
                    required
                    return-object
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="Référence *"
                    v-model="facture.reference"
                    hint="Exemple : FVT_123_XX"
                    :rules="[v => !!v || 'Indiquez la référence']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="N° de dossier"
                    v-model="facture.noDossier"
                    hint="N° de dossier ou n° de commande"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Intitulé *"
                    v-model="facture.intitule"
                    hint="Exemple : « Formation Python avancé »"
                    :rules="[v => !!v || 'Indiquez l\'intitulé de la facture']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>

              <v-card-title>
                Informations supplémentaires
              </v-card-title>
              <v-row
                v-for="i in indexLigneInfos+1"
                :key="'infos-'+i"
              >
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Titre"
                    v-model="infosAdd[i-1].titre"
                    hint="Exemple : « Nombre de participants »"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-text-field
                    outlined
                    label="Valeur"
                    v-model="infosAdd[i-1].valeur"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <div class="divider mb-5">
                <v-divider />
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      class="btnRetirerLigne"
                      v-if="indexLigneInfos > 0"
                      small
                      color="gray"
                      @click="removeLigneInfos()"
                    >
                      <v-icon class="mr-2">
                        mdi-minus-circle
                      </v-icon>
                      Retirer une ligne
                    </v-btn>
                    <v-btn
                      class="btnAjouterLigne"
                      small
                      color="gray"
                      @click="addLigneInfos()"
                    >
                      <v-icon class="mr-2">
                        mdi-plus-circle
                      </v-icon>
                      Ajouter une ligne
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-card-title>
                Détail de la facture
              </v-card-title>
              <v-row
                v-for="i in indexLigneDetail+1"
                :key="'detail-'+i"
              >
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    outlined
                    label="Désignation *"
                    v-model="facture.lignesFacture[i-1].libelle"
                    hint="Exemple : « Journée de formation »"
                    :rules="[v => !!v || 'Indiquez la désignation']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="1"
                >
                  <v-text-field
                    outlined
                    label="Quantité *"
                    class="align-right no-arrows"
                    type="number"
                    v-model="facture.lignesFacture[i-1].unites"
                    :rules="[v => !!v || 'Indiquez les unités']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Prix unitaire *"
                    class="align-right no-arrows"
                    type="number"
                    suffix="€"
                    hint="Prix unitaire hors taxe"
                    v-model="facture.lignesFacture[i-1].prixUnitaire"
                    :rules="[v => !!v || 'Indiquez le prix unitaire']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Total"
                    class="align-right"
                    type="number"
                    suffix="€"
                    disabled
                    :value="Math.round((facture.lignesFacture[i-1].unites * facture.lignesFacture[i-1].prixUnitaire + Number.EPSILON) * 100) / 100 || 0"
                  />
                </v-col>
              </v-row>
              <div class="divider">
                <v-divider />
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      class="btnRetirerLigne"
                      v-if="indexLigneDetail > 0"
                      small
                      color="gray"
                      @click="removeLigneDetail()"
                    >
                      <v-icon class="mr-2">
                        mdi-minus-circle
                      </v-icon>
                      Retirer une ligne
                    </v-btn>
                    <v-btn
                      class="btnAjouterLigne"
                      small
                      color="gray"
                      @click="addLigneDetail()"
                    >
                      <v-icon class="mr-2">
                        mdi-plus-circle
                      </v-icon>
                      Ajouter une ligne
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    Sous-total HT
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="facture.montantHT"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTotalHT()"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    TVA ({{ tauxTVA }} %)
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="facture.montantTVA"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTVA()"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    Montant total
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="facture.montantTotal"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTotalTTC()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      v-if="!$route.params.id"
                      color="primary"
                      class="mr-0"
                      type="submit"
                    >
                      Générer la facture
                    </v-btn>
                    <v-btn
                      v-if="$route.params.id"
                      color="primary"
                      class="mr-0"
                      type="submit"
                      @click="updateInvoice()"
                      :disabled="updateDisabled"
                    >
                      Mettre à jour la facture
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        formEdited: false,
        clientDisabled: false,
        clients: [],
        financeurs: [],
        formations: [],
        autofinancement: true,
        displayEtat: {
          OUVERT: 'OUVERTE',
          CONFIRME: 'CONFIRMÉE',
          ENCOURS: 'EN COURS',
          TERMINE: 'TERMINÉE',
          FERME: 'FERMÉE',
          ANNULE: 'ANNULÉE',
        },
        indexLigneDetail: 0,
        indexLigneInfos: 0,
        updateDisabled: false,
        facture: {
          reference: '',
          numero: '',
          client: null,
          destinataire: {},
          dateEmission: new Date(),
          dateEnvoi: null,
          dateEcheance: null,
          datePaiement: null,
          montantHT: 0,
          montantTVA: 0,
          montantTotal: 0,
          intitule: '',
          noDossier: '',
          frais: 0.0,
          etat: 'NOUVEAU',
          lienFacture: '',
          formationAssociee: null,
          informationsAdditionnelles: {},
          lignesFacture: [{}],
        },
        infosAdd: [],
        formationAssocieeId: null,
        tauxTVA: 20,
      };
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      this.infosAdd = [{}];

      if (!this.$route.params.id) {
        // create mode
        this.axios.get('/facturesSortantes/next')
          .then((res) => {
            this.facture.numero = res.data;
          })
          .finally((res) => {
            // Si la facture a été créée à partir d'une formation
            if (this.$route.params.formationId) {
              // Récupérer l'id de la formation associée (infos partielles seulement : une requête est effectuée ensuite pour tout récupérer)
              this.formationAssocieeId = this.$route.params.formationId;
              this.updateFactureWithInfosFormation();
            }
          });
      } else {
        // edit mode
        this.axios.get('/facturesSortantes/' + this.$route.params.id)
          .then((res) => {
            this.facture = res.data;
            for (const [key, val] of Object.entries(this.facture.informationsAdditionnelles)) {
              this.infosAdd.push({ titre: key, valeur: val });
            }
            this.indexLigneInfos = this.infosAdd.length - 1;
            this.indexLigneDetail = this.facture.lignesFacture.length - 1;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération de la facture', type: 'warn' });
          });
      }

      // Si la facture n'a pas été créée à partir d'une formation, récupérer tous les clients
      if (!this.$route.params.formationId) {
        this.axios.get('/clients')
          .then((res) => {
            this.clients = res.data;
            this.clients.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
          });
      }

      this.axios.get('/financeurs')
        .then((res) => {
          this.financeurs = res.data;
          this.financeurs.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des financeurs', type: 'warn' });
        });

      this.axios.get('/configuration/getValue/tauxTvaDefaut')
        .then((res) => {
          this.tauxTVA = parseFloat(res.data);
        });

      this.axios.get('/formations')
        .then((res) => {
          const order = ['TERMINE', 'ENCOURS', 'CONFIRME', 'OUVERT', 'ANNULE', 'FERME'];
          this.formations = res.data.sort((a, b) => order.indexOf(a.etat) - order.indexOf(b.etat) || new Date(b.dateDebut) - new Date(a.dateDebut));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formations', type: 'warn' });
        });
    },
    methods: {
      updateFactureWithInfosFormation() {
        this.axios.get('/formations/' + this.formationAssocieeId)
          .then((res) => {
            this.facture.formationAssociee = res.data;
            this.getInfosFromFormation();
            this.getLignesFactureFromBudget();
            this.getLignesFactureFromRecettes();
            if (this.facture.client) {
              this.setFinanceurParDefaut();
            }
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération des données de la formation', type: 'warn' });
          });
      },

      getInfosFromFormation() {
        // Méthode appelée : soit quand une facture est créée à partir d'une formation, soit quand on choisit une formation dans la liste
        // Met à jour les données de la facture à partir des données de la formation
        this.facture.intitule = this.facture.formationAssociee.intitule;
        this.infosAdd.push({});
        this.infosAdd[0] = { titre: 'Dates de la formation', valeur: this.formatDate(this.facture.formationAssociee.dateDebut) + ' - ' + this.formatDate(this.facture.formationAssociee.dateFin) };
        this.infosAdd[1] = { titre: 'Lieu de la formation', valeur: this.facture.formationAssociee.distanciel ? 'Classe virtuelle' : this.formatAdresse(this.facture.formationAssociee.adresse) };
        this.facture.noDossier = this.facture.formationAssociee.reference;

        if (this.facture.formationAssociee.type !== 'INTER') {
          // empêcher de changer de client dans le cas d'une INTRA ou SSTRT
          this.clientDisabled = true;
          this.facture.client = this.facture.formationAssociee.client;
          this.clients = this.facture.client ? [this.facture.client] : [];
          this.updateReference();
        } else {
          this.clientDisabled = false;
          // Proposer uniquement les clients associés à cette formation (ayant des stagiaires inscrits)
          this.clients = this.facture.formationAssociee.stagiaires.map(s => s.societe);
        }
      },

      setFinanceurParDefaut() {
        if (this.facture.client.financeurParDefaut) {
          this.autofinancement = false;
          this.facture.destinataire = this.facture.client.financeurParDefaut;
        }
      },

      getLignesFactureFromBudget() {
        // Méthode appelée : soit quand une facture est créée à partir d'une formation (INTRA / SSTRT), soit quand on choisit une formation et un client dans la liste
        // Met à jour les lignes de la facture à partir des données du budget de la formation
        if (this.facture.formationAssociee && this.facture.client) {
          // Supprimer la ligne qui ne sert à rien
          this.indexLigneDetail--;
          this.facture.lignesFacture = [];

          // Récupérer le budget du client sélectionné
          const budgetClient = this.facture.formationAssociee.budgets.find(b => b.client.id === this.facture.client.id);

          switch (budgetClient.modeCalcul) {
            case 'STAGIAIRE': {
              const stagiairesOfClient = this.facture.formationAssociee.stagiaires.filter(stagiaire => stagiaire.societe.id === this.facture.client.id);

              for (const stagiaire of stagiairesOfClient) {
                const ligne = {
                  libelle: 'Formation de ' + stagiaire.prenom + ' ' + stagiaire.nom,
                  unites: 1,
                  prixUnitaire: budgetClient.cout,
                }
                this.facture.lignesFacture.push(ligne);
                this.indexLigneDetail++;
              }
              break;
            }
            case 'TOTAL': {
              const ligne = {
                libelle: 'Coûts pédagogiques',
                unites: 1,
                prixUnitaire: budgetClient.cout,
              }
              this.facture.lignesFacture.push(ligne);
              this.indexLigneDetail++;
              break;
            }
            case 'JOUR': {
              const dureeJours = [...new Set(this.facture.formationAssociee.creneaux.map(creneau => creneau.date))].length;
              const ligne = {
                libelle: 'Journée de formation',
                unites: dureeJours,
                prixUnitaire: budgetClient.cout,
              }
              this.facture.lignesFacture.push(ligne);
              this.indexLigneDetail++;
              break;
            }
            case 'HEURE': {
              const dureeHeures = (this.facture.formationAssociee.creneaux.map(creneau => {
                const heureDebut = new Date('01/01/2000 ' + creneau.heureDebut);
                const heureFin = new Date('01/01/2000 ' + creneau.heureFin);
                return heureFin.getTime() - heureDebut.getTime();
              }).reduce((a, b) => a + b, 0) / 3600000);
              const ligne = {
                libelle: 'Heure de formation',
                unites: dureeHeures,
                prixUnitaire: budgetClient.cout,
              }
              this.facture.lignesFacture.push(ligne);
              this.indexLigneDetail++;
              break;
            }
          }
        }
      },

      getLignesFactureFromRecettes() {
        // Pareil que getLignesFactureFromBudget()
        if (this.facture.formationAssociee && this.facture.client) {
          const recettesClient = this.facture.formationAssociee.recettes.filter(r => r.client && (r.client.id === this.facture.client.id));
          for (const recette of recettesClient) {
            const ligne = {
              libelle: recette.libelle,
              unites: recette.unites,
              prixUnitaire: recette.prixUnitaire,
            }
            this.facture.lignesFacture.push(ligne);
            this.indexLigneDetail++;
          }
        }
      },

      updateReference() {
        let num = '000';
        if (this.facture.numero < 10) {
          num = '00' + this.facture.numero;
        } else if (this.facture.numero < 100) {
          num = '0' + this.facture.numero;
        } else {
          num = this.facture.numero;
        }
        this.facture.reference = 'FVT_' + num + '_' + (this.facture.client ? this.facture.client.codeFacture : '');
      },

      calculTotalHT() {
        let sousTotal = 0;
        for (const ligne of this.facture.lignesFacture) {
          sousTotal += ligne.unites * ligne.prixUnitaire || 0;
        }
        sousTotal = Math.round((sousTotal + Number.EPSILON) * 100) / 100
        this.facture.montantHT = sousTotal;
        return sousTotal;
      },

      calculTVA() {
        let tva = this.facture.montantHT * (this.tauxTVA / 100) || 0;
        tva = Math.round((tva + Number.EPSILON) * 100) / 100
        this.facture.montantTVA = tva
        return tva;
      },

      calculTotalTTC() {
        let ttc = this.facture.montantHT * (1 + (this.tauxTVA / 100)) || 0;
        ttc = Math.round((ttc + Number.EPSILON) * 100) / 100
        this.facture.montantTotal = ttc
        return ttc;
      },

      addLigneDetail() {
        this.facture.lignesFacture.push({});
        this.indexLigneDetail++;
      },

      removeLigneDetail() {
        this.facture.lignesFacture.pop();
        this.indexLigneDetail--;
      },

      addLigneInfos() {
        this.infosAdd.push({});
        this.indexLigneInfos++;
      },

      removeLigneInfos() {
        this.infosAdd.pop();
        this.indexLigneInfos--;
      },

      validate () {
        this.formEdited = false;
        if (this.$refs.facture.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateInvoice();
          } else {
            // create mode
            this.createInvoice();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createInvoice() {
        this.infosAdd.forEach((obj) => {
          this.facture.informationsAdditionnelles[obj.titre] = obj.valeur;
        });

        if (this.autofinancement) {
          this.facture.destinataire = this.facture.client;
          this.facture.destinataire.nature = 'CLIENT';
        } else {
          this.facture.destinataire.nature = 'FINANCEUR';
        }

        this.lightDataInvoice();

        this.axios.post('/facturesSortantes', this.facture)
          .then(res => {
            this.setSnack({ text: 'La facture a bien été générée', type: 'success' });
            this.$router.push({ name: 'FactureDetail', params: { id: res.data.id } });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la génération de la facture', type: 'warn' });
          })
      },

      updateInvoice() {
        this.updateDisabled = true;

        this.infosAdd.forEach((obj) => {
          this.facture.informationsAdditionnelles[obj.titre] = obj.valeur;
        });

        if (this.autofinancement) {
          this.facture.destinataire = this.facture.client;
          this.facture.destinataire.nature = 'CLIENT';
        } else {
          this.facture.destinataire.nature = 'FINANCEUR';
        }

        this.lightDataInvoice();

        this.axios.put('/facturesSortantes/' + this.facture.id, this.facture)
          .then(response => {
            this.setSnack({ text: 'La facture a bien été mise à jour', type: 'success' });
            this.$router.push({ name: 'FactureDetail', params: { id: this.facture.id } });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la génération de la facture', type: 'warn' });
            this.updateDisabled = false;
          })
      },

      lightDataInvoice() {
        delete this.facture.client.adresse;
        delete this.facture.client.contacts;
        delete this.facture.client.contactsAdministratif;
        delete this.facture.client.contactsCommercial;
        delete this.facture.client.contactsFacturation;
        delete this.facture.destinataire.adresse;
        delete this.facture.destinataire.contacts;
        delete this.facture.destinataire.contactsAdministratif;
        delete this.facture.destinataire.contactsCommercial;
        delete this.facture.destinataire.contactsFacturation;
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      formatAdresse(adresse) {
        let formattedAdresse = '';
        formattedAdresse += adresse.adressePostale ? adresse.adressePostale.replace(/[\n\r]+/g, ', ') + ' ' : '';
        formattedAdresse += adresse.codePostal ? adresse.codePostal + ' ' : '';
        formattedAdresse += adresse.ville ?? '';
        formattedAdresse += adresse.pays ? ' ' + adresse.pays + ' ' : '';
        return formattedAdresse;
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>

  #create-facture >>> .divider {
    position: relative;
  }

  #create-facture >>> .btnAjouterLigne {
    position: absolute;
    top: -14px;
    right: 0;
  }

  #create-facture >>> .btnRetirerLigne {
    position: absolute;
    top: -14px;
    right: 150px;
  }

</style>
